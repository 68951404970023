import React, { memo } from 'react';
import PageContext from '../contexts/PageContext';
import EducationA from './blocks/Education/EducationA';
import HeadingA from './blocks/Heading/HeadingA';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import ProjectsA from './blocks/Projects/ProjectsA';
import WorkA from './blocks/Work/WorkA';
import SkillsA from './blocks/Skills/SkillsA';
import AwardsA from './blocks/Awards/AwardsA';
import CertificationsA from './blocks/Certifications/CertificationsA';
import { hasAddress, isSectionExist } from '../utils';

const Blocks = {
  objective: ObjectiveA,
  work: WorkA,
  education: EducationA,
  projects: ProjectsA,
  skills: SkillsA,
  awards: AwardsA,
  certifications: CertificationsA,
};

const Onyx = ({ data }) => {
  const layout = data.metadata.layout.onyx;

  return (
    <PageContext.Provider value={{ data, heading: HeadingA }}>
      <div
        id="page"
        className="p-8 rounded"
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          backgroundColor: data.metadata.colors.background,
        }}
      >
        <div className="text-center">
          <h1
            className="font-bold text-4xl"
            style={{
              color: data.metadata.colors.primary,
              fontFamily: data.metadata.font,
            }}
          >
            {data.profile.firstName} {data.profile.lastName}
          </h1>

          {hasAddress(data.profile.address) && (
            <div className="mt-4 text-xs">
              <span>
                {data.profile.address.city} {data.profile.address.pincode} |{' '}
                {data.profile.linkedin
                  ? data.profile.linkedin.length
                    ? `${data.profile.linkedin} |`
                    : null
                  : null}{' '}
                {data.profile.phone
                  ? data.profile.phone.length
                    ? `${data.profile.phone} |`
                    : null
                  : null}{' '}
                {data.profile.email}
              </span>
            </div>
          )}
        </div>

        <div className="grid gap-4">
          {layout[0] &&
            layout[0].map((x) => {
              const Component = Blocks[x];

              return isSectionExist(x, data[x]) && Component ? (
                <div>
                  <hr
                    className="my-5 opacity-25"
                    style={{ borderColor: data.metadata.colors.text }}
                  />
                  <Component key={x} />
                </div>
              ) : null;
            })}

          <div className="grid grid-cols-2 gap-4">
            {layout[1] &&
              layout[1].map((x) => {
                const Component = Blocks[x];
                return Component && <Component key={x} />;
              })}
          </div>

          {layout[2] &&
            layout[2].map((x) => {
              const Component = Blocks[x];
              return Component && <Component key={x} />;
            })}
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default memo(Onyx);
