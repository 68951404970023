import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, safetyCheck, isItemVisible } from '../../../utils';

const InformalEducationItem = ({ item, language }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-semibold text-sm">{item.trainingTopic}</h6>
          <span className="text-xs">
            <strong>{item.score}</strong>
          </span>
        </div>
        <div className="flex flex-col items-end text-right">
          {item.pickupAt && (
            <h6 className="text-xs font-medium mb-1">
              (
              {formatDateRange(
                {
                  startDate: item.pickupAt,
                  endDate: item.expAt,
                  language,
                },
                t,
              )}
              )
            </h6>
          )}
          <span className="text-sm font-medium">{item.credentialNumber}</span>
        </div>
      </div>
    </div>
  );
};

const InformalEducationA = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.informalEducation) ? (
    <div>
      <Heading>{data.informalEducation.heading}</Heading>
      <div className="grid gap-4">
        {data.informalEducation.items.map(
          (x) =>
            isItemVisible(x) && (
              <InformalEducationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(InformalEducationA);
