import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import PageContext from '../../../contexts/PageContext';
import InformalEducation from '../InformalEducation/InformalEducationA';
import { formatDateRange, safetyCheck, isItemVisible } from '../../../utils';

const EducationItem = ({ item, language }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-semibold text-sm">{item.institution}</h6>
          <span className="text-xs">
            <strong>{item.degree}</strong> {item.field}
          </span>
        </div>
        <div className="flex flex-col items-end text-right">
          {item.startDate && (
            <h6 className="text-xs font-medium mb-1">
              (
              {formatDateRange(
                {
                  startDate: item.startDate,
                  endDate: item.endDate,
                  language,
                },
                t,
              )}
              )
            </h6>
          )}
          <span className="text-sm font-medium">{item.gpa}</span>
        </div>
      </div>
      {item.summary && (
        <ReactMarkdown
          className="markdown mt-2 text-sm"
          source={item.summary}
        />
      )}
    </div>
  );
};

const EducationA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  return (
    <div style={{display: 'block', paddingTop: '1.2rem'}}>
      {safetyCheck(data.education) && (
         /* to split a page when the components are overloaded on that page */
        <div style={{ breakInside: 'avoid', display: 'block' }}>
          <Heading>{data.education.heading}</Heading>
          <div className="grid gap-4">
            {data.education.items.map(
              (x) =>
                isItemVisible(x) && (
                  <EducationItem
                    key={x.id}
                    item={x}
                    language={data.metadata.language}
                  />
                ),
            )}
          </div>
        </div>
      )}
      {safetyCheck(data.informalEducation) ?
       /* to split a page when the components are overloaded on that page */
        <div className='mt-10' style={{ breakInside: 'avoid' }}>
          <InformalEducation />
        </div>
        :
        null        
      }

    </div>
  );
};

export default memo(EducationA);
