import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { safetyCheck, isItemVisible } from '../../../utils';

const SkillItem = (x, idx, index) => {
  const { name, level } = x;
  if (level === '' || level === undefined) {
    return `${name}${idx === index ? '.' : ', '}`;
  } else {
    return `${name} (${level})${idx === index ? '.' : ', '}`;
  }
};

const SkillsA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  let index = 0;
  const boolData =
    safetyCheck(data.skills) && data.skills.items.map((x) => isItemVisible(x));
  boolData &&
    boolData.map((x, idx) => {
      if (x) {
        index = idx;
      }
    });

  return safetyCheck(data.skills) ? (
     /* to split a page when the components are overloaded on that page */
    <div style={{ breakInside: 'avoid', display: 'block' }}>
      <Heading>{data.skills.heading}</Heading>
      <div>
        <div className="flex flex-col markdown mt-2 text-sm">
          {data.skills.items.map(
            (x, idx) => isItemVisible(x) && SkillItem(x, idx, index),
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default memo(SkillsA);
